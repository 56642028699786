import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c99546e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "centiseconds"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(['programRunnerTimer', _ctx.isSmall ? 'isSmall' : ''])
  }, [
    (_ctx.hours !== '00')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.hours) + ":", 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.minutes) + ":", 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.seconds), 1),
    (!_ctx.hideCentiseconds)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.centiseconds), 1))
      : _createCommentVNode("", true)
  ], 2))
}