
import { defineComponent } from 'vue'
import Step from '../types/step.d'

export default defineComponent({
  name: 'ProgramRunnerProgress',
  props: {
    steps: { type: Array as () => Array<Step>, required: true },
    currentStep: { type: Object as () => Step },
  },
  data() {
    return {
      totalDuration: 0,
    }
  },
  watch: {
    steps: {
      handler: function (steps: Array<Step>) {
        this.totalDuration = steps.reduce((prev: number, curr: Step) => {
          return curr.duration > 0 ? prev + curr.duration : prev + 60
        }, 0)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getElementStyle(duration: number) {
      if (duration === 0) duration = 60
      const width = `${(duration * 100) / this.totalDuration}%`
      return { width }
    },
  },
})
