import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f003cfc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_program_runner = _resolveComponent("program-runner")!
  const _component_icon_dumbbell = _resolveComponent("icon-dumbbell")!
  const _component_icon = _resolveComponent("icon")!
  const _component_icon_mute = _resolveComponent("icon-mute")!
  const _component_icon_sound = _resolveComponent("icon-sound")!
  const _component_icon_question = _resolveComponent("icon-question")!
  const _component_workout_manager = _resolveComponent("workout-manager")!
  const _component_program_builder = _resolveComponent("program-builder")!
  const _component_about = _resolveComponent("about")!
  const _component_icon_close = _resolveComponent("icon-close")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", {
      class: _normalizeClass({ isFull: _ctx.visibleSection === _ctx.sectionTypes.Main })
    }, [
      _createVNode(_component_program_runner, {
        workout: _ctx.workout,
        isMute: _ctx.isMute
      }, null, 8, ["workout", "isMute"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showWorkout && _ctx.showWorkout(...args))),
          "aria-label": "Show the workout program"
        }, [
          _createVNode(_component_icon, {
            width: "28",
            height: "28"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_dumbbell)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleMute && _ctx.toggleMute(...args))),
          "aria-label": "Mute/unmute the sound"
        }, [
          (_ctx.isMute)
            ? (_openBlock(), _createBlock(_component_icon, {
                key: 0,
                width: "28",
                height: "28"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_mute)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_icon, {
                key: 1,
                width: "28",
                height: "28"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_sound)
                ]),
                _: 1
              }))
        ]),
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showAbout && _ctx.showAbout(...args))),
          "aria-label": "Show the about section"
        }, [
          _createVNode(_component_icon, {
            width: "28",
            height: "28"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_question)
            ]),
            _: 1
          })
        ])
      ])
    ], 2),
    _createElementVNode("aside", {
      class: _normalizeClass({ isVisible: _ctx.visibleSection !== _ctx.sectionTypes.Main })
    }, [
      (_ctx.visibleSection === _ctx.sectionTypes.Workout)
        ? (_openBlock(), _createBlock(_component_workout_manager, {
            key: 0,
            workout: _ctx.workout,
            workouts: _ctx.workouts,
            onCreateWorkout: _ctx.createWorkout,
            onRenameWorkout: _ctx.renameWorkout,
            onSelectWorkout: _ctx.selectWorkout,
            onDeleteWorkout: _ctx.deleteWorkout
          }, null, 8, ["workout", "workouts", "onCreateWorkout", "onRenameWorkout", "onSelectWorkout", "onDeleteWorkout"]))
        : _createCommentVNode("", true),
      (_ctx.visibleSection === _ctx.sectionTypes.Workout)
        ? (_openBlock(), _createBlock(_component_program_builder, {
            key: 1,
            workout: _ctx.workout,
            onAddStep: _ctx.addStep,
            onUpdateStep: _ctx.updateStep,
            onCloneStep: _ctx.cloneStep,
            onDeleteStep: _ctx.deleteStep,
            onMoveStep: _ctx.moveStep
          }, null, 8, ["workout", "onAddStep", "onUpdateStep", "onCloneStep", "onDeleteStep", "onMoveStep"]))
        : _createCommentVNode("", true),
      (_ctx.visibleSection === _ctx.sectionTypes.About)
        ? (_openBlock(), _createBlock(_component_about, { key: 2 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hideAside && _ctx.hideAside(...args))),
          "aria-label": "Close aside"
        }, [
          _createVNode(_component_icon, {
            width: "28",
            height: "28"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_close)
            ]),
            _: 1
          })
        ])
      ])
    ], 2)
  ], 64))
}