import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-008c6594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "programRunnerProgress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step) => {
        return (_openBlock(), _createElementBlock("li", {
          key: step.id,
          class: _normalizeClass([step === _ctx.currentStep ? 'current' : '', `${step.type.toLowerCase()}Color`]),
          style: _normalizeStyle(_ctx.getElementStyle(step.duration))
        }, null, 6))
      }), 128))
    ])
  ]))
}