
import { defineComponent } from 'vue'
import Workout from '../types/workout.d'
import Step from '../types/step.d'
import ProgramBuilderStep from './ProgramBuilderStep.vue'
import RoundedButton from './RoundedButton.vue'
import Icon from './icons/Icon.vue'
import IconAddCircle from './icons/IconAddCircle.vue'
import generateId from '@/helpers/generateId'

export default defineComponent({
  name: 'ProgramBuilder',
  components: {
    ProgramBuilderStep,
    RoundedButton,
    Icon,
    IconAddCircle,
  },
  props: {
    workout: { type: Object as () => Workout, required: true },
  },
  data() {
    return {
      editingStep: '',
    }
  },
  methods: {
    handleClickOnAddButton() {
      const newId = generateId()
      this.$emit('add-step', newId)
      this.editingStep = newId
    },
    handleStepStartEditing(id: string) {
      this.editingStep = this.editingStep !== id ? id : ''
    },
    handleStepUpdate(id: string, data: Step) {
      const index = this.workout.steps.findIndex((step) => step.id === id)
      if (index === -1) return
      this.$emit('update-step', index, {
        ...this.workout.steps[index],
        ...data,
      })
    },
    handleStepClone(id: string) {
      const index = this.workout.steps.findIndex((step) => step.id === id)
      if (index === -1) return

      const newId = generateId()
      this.$emit('clone-step', index, newId)
      this.editingStep = newId
    },
    handleStepDelete(id: string) {
      const index = this.workout.steps.findIndex((step) => step.id === id)
      if (index === -1) return
      this.$emit('delete-step', index)
    },
    handleStepUp(id: string) {
      const index = this.workout.steps.findIndex((step) => step.id === id)
      if (index === -1) return
      this.$emit('move-step', index, index - 1)
    },
    handleStepDown(id: string) {
      const index = this.workout.steps.findIndex((step) => step.id === id)
      if (index === -1) return
      this.$emit('move-step', index, index + 1)
    },
    dragStart(event: DragEvent, index: number) {
      if (!event.dataTransfer) return

      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('index', String(index))
    },
    dragEnter(event: DragEvent) {
      const target = event.target as HTMLElement
      target.classList.add('drag-over')
    },
    dragLeave(event: DragEvent) {
      const target = event.target as HTMLElement
      target.classList.remove('drag-over')
    },
    dragDrop(event: DragEvent, newIndex: number) {
      if (!event.dataTransfer) return

      const target = event.target as HTMLElement
      target.classList.remove('drag-over')

      const index = parseInt(event.dataTransfer.getData('index'))
      // If we are moving an element lower, we need to shift the position
      if (newIndex > index) {
        newIndex--
      }
      this.$emit('move-step', index, newIndex)
    },
  },
})
