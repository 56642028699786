
import { defineComponent } from 'vue'
import Workout from '../types/workout.d'
import RoundedButton from './RoundedButton.vue'
import Icon from './icons/Icon.vue'
import IconAddCircle from './icons/IconAddCircle.vue'
import IconCaretDown from './icons/IconCaretDown.vue'
import IconCheck from './icons/IconCheck.vue'
import IconClose from './icons/IconClose.vue'
import IconEdit from './icons/IconEdit.vue'
import IconTrash from './icons/IconTrash.vue'

export default defineComponent({
  name: 'WorkoutManager',
  components: {
    RoundedButton,
    Icon,
    IconAddCircle,
    IconCaretDown,
    IconCheck,
    IconClose,
    IconEdit,
    IconTrash,
  },
  props: {
    workout: { type: Object as () => Workout, required: true },
    workouts: { type: Array as () => Workout[], required: true },
  },
  methods: {
    toggleSelect() {
      this.isSelecting = !this.isSelecting
      if (this.isSelecting && this.isDeleting) {
        this.isDeleting = false
      }
    },
    selectWorkout(id: string) {
      this.$emit('select-workout', id)
      this.isSelecting = false
    },
    createWorkout() {
      this.$emit('create-workout')
      this.isSelecting = false
    },
    toggleRename() {
      this.isRenaming = !this.isRenaming
    },
    toggleDelete() {
      this.isDeleting = !this.isDeleting
    },
    renameWorkout() {
      this.$emit('rename-workout', this.name)
      this.isRenaming = false
    },
    cancelRenaming() {
      this.name = this.workout.name
      this.isRenaming = false
    },
    deleteWorkout() {
      this.$emit('delete-workout')
      this.isDeleting = false
    },
    cancelDeleting() {
      this.isDeleting = false
    },
    handleNameUpdate(event: Event) {
      const { value } = event.currentTarget as HTMLInputElement
      this.name = value
    },
  },
  data() {
    return {
      name: this.workout.name,
      isSelecting: false,
      isRenaming: false,
      isDeleting: false,
    }
  },
  watch: {
    workout() {
      this.name = this.workout.name
    },
  },
})
