
import { defineComponent } from 'vue'
import Step from '../types/step.d'
import StepType from '../types/stepType'
import { formatMinutes, formatSeconds } from '../helpers/formatters'
import Icon from './icons/Icon.vue'
import IconTrash from './icons/IconTrash.vue'
import IconCopy from './icons/IconCopy.vue'
import IconArrowUp from './icons/IconArrowUp.vue'
import IconArrowDown from './icons/IconArrowDown.vue'
import IconEdit from './icons/IconEdit.vue'
import IconCheck from './icons/IconCheck.vue'

export default defineComponent({
  name: 'ProgramBuilderStep',
  components: {
    Icon,
    IconTrash,
    IconCopy,
    IconArrowUp,
    IconArrowDown,
    IconEdit,
    IconCheck,
  },
  props: {
    step: { type: Object as () => Step, required: true },
    isFirst: Boolean,
    isLast: Boolean,
    isEditing: Boolean,
  },
  data() {
    return {
      StepType,
    }
  },
  computed: {
    classes(): Array<string> {
      return ['programBuilderStep', `${this.step.type.toLowerCase()}Color`]
    },
    stepTypes(): Array<{ value: string; label: string | StepType }> {
      return Object.entries(StepType).map(([label, value]) => ({
        value,
        label,
      }))
    },
    minutes(): string {
      return formatMinutes(this.step.duration)
    },
    seconds(): string {
      return formatSeconds(this.step.duration)
    },
  },
  methods: {
    handleStepEdit() {
      this.$emit('startEditing', this.step.id)
    },
    handleUpdate(event: Event) {
      const { name, value } = event.currentTarget as HTMLInputElement
      switch (name) {
        case 'minutes':
          this.$emit('update', this.step.id, {
            duration: parseInt(value) * 60 + (this.step.duration % 60),
          })
          break
        case 'seconds':
          this.$emit('update', this.step.id, {
            duration: Math.floor(this.step.duration / 60) * 60 + parseInt(value),
          })
          break
        case 'type':
          this.$emit('update', this.step.id, {
            [name]: value,
            // Keep the step name only if the step is an exercise
            name: value === StepType.Exercise ? this.step.name : '',
          })
          break
        case 'stepName':
          this.$emit('update', this.step.id, { name: value })
          break
        default:
          this.$emit('update', this.step.id, { [name]: value })
      }
    },
    handleStepClone() {
      this.$emit('clone', this.step.id)
    },
    handleStepDelete() {
      this.$emit('delete', this.step.id)
    },
    handleClickOnMoveUpButton() {
      this.$emit('up', this.step.id)
    },
    handleClickOnMoveDownButton() {
      this.$emit('down', this.step.id)
    },
  },
})
