<template>
  <path
    d="M6.40002 2.4C6.18784 2.4 5.98436 2.48428 5.83433 2.63431C5.6843 2.78434 5.60002 2.98783 5.60002 3.2V3.6C5.60002 3.70609 5.55788 3.80783 5.48286 3.88284C5.40785 3.95786 5.3061 4 5.20002 4C5.09393 4 4.99219 3.95786 4.91718 3.88284C4.84216 3.80783 4.80002 3.70609 4.80002 3.6V3.2C4.80002 2.77565 4.96859 2.36869 5.26865 2.06863C5.56871 1.76857 5.97567 1.6 6.40002 1.6H6.80002C6.9061 1.6 7.00785 1.64214 7.08286 1.71716C7.15788 1.79217 7.20002 1.89391 7.20002 2C7.20002 2.10609 7.15788 2.20783 7.08286 2.28284C7.00785 2.35786 6.9061 2.4 6.80002 2.4H6.40002Z"
  />
  <path
    d="M5.60002 9.6C5.60002 9.81217 5.6843 10.0157 5.83433 10.1657C5.98436 10.3157 6.18784 10.4 6.40002 10.4H6.80002C6.9061 10.4 7.00785 10.4421 7.08286 10.5172C7.15788 10.5922 7.20002 10.6939 7.20002 10.8C7.20002 10.9061 7.15788 11.0078 7.08286 11.0828C7.00785 11.1579 6.9061 11.2 6.80002 11.2H6.40002C5.97567 11.2 5.56871 11.0314 5.26865 10.7314C4.96859 10.4313 4.80002 10.0243 4.80002 9.6V9.2C4.80002 9.09391 4.84216 8.99217 4.91718 8.91715C4.99219 8.84214 5.09393 8.8 5.20002 8.8C5.3061 8.8 5.40785 8.84214 5.48286 8.91715C5.55788 8.99217 5.60002 9.09391 5.60002 9.2V9.6Z"
  />
  <path
    d="M5.60002 5.2C5.60002 5.09391 5.55788 4.99217 5.48286 4.91715C5.40785 4.84214 5.3061 4.8 5.20002 4.8C5.09393 4.8 4.99219 4.84214 4.91718 4.91715C4.84216 4.99217 4.80002 5.09391 4.80002 5.2V7.6C4.80002 7.70608 4.84216 7.80782 4.91718 7.88284C4.99219 7.95785 5.09393 8 5.20002 8C5.3061 8 5.40785 7.95785 5.48286 7.88284C5.55788 7.80782 5.60002 7.70608 5.60002 7.6V5.2Z"
  />
  <path
    d="M12.8 2.4C13.0122 2.4 13.2157 2.48428 13.3657 2.63431C13.5157 2.78434 13.6 2.98783 13.6 3.2V3.6C13.6 3.70609 13.6421 3.80783 13.7172 3.88284C13.7922 3.95786 13.8939 4 14 4C14.1061 4 14.2078 3.95786 14.2828 3.88284C14.3579 3.80783 14.4 3.70609 14.4 3.6V3.2C14.4 2.77565 14.2314 2.36869 13.9314 2.06863C13.6313 1.76857 13.2243 1.6 12.8 1.6H12.4C12.2939 1.6 12.1922 1.64214 12.1172 1.71716C12.0421 1.79217 12 1.89391 12 2C12 2.10609 12.0421 2.20783 12.1172 2.28284C12.1922 2.35786 12.2939 2.4 12.4 2.4H12.8Z"
  />
  <path
    d="M12.8 10.4C13.0122 10.4 13.2157 10.3157 13.3657 10.1657C13.5157 10.0157 13.6 9.81217 13.6 9.6V9.2C13.6 9.09391 13.6421 8.99217 13.7172 8.91715C13.7922 8.84214 13.8939 8.8 14 8.8C14.1061 8.8 14.2078 8.84214 14.2828 8.91715C14.3579 8.99217 14.4 9.09391 14.4 9.2V9.6C14.4 10.0243 14.2314 10.4313 13.9314 10.7314C13.6313 11.0314 13.2243 11.2 12.8 11.2H12.4C12.2939 11.2 12.1922 11.1579 12.1172 11.0828C12.0421 11.0078 12 10.9061 12 10.8C12 10.6939 12.0421 10.5922 12.1172 10.5172C12.1922 10.4421 12.2939 10.4 12.4 10.4H12.8Z"
  />
  <path
    d="M14 4.8C13.8939 4.8 13.7922 4.84214 13.7172 4.91715C13.6421 4.99217 13.6 5.09391 13.6 5.2V7.6C13.6 7.70608 13.6421 7.80782 13.7172 7.88284C13.7922 7.95785 13.8939 8 14 8C14.1061 8 14.2078 7.95785 14.2828 7.88284C14.3579 7.80782 14.4 7.70608 14.4 7.6V5.2C14.4 5.09391 14.3579 4.99217 14.2828 4.91715C14.2078 4.84214 14.1061 4.8 14 4.8Z"
  />
  <path
    d="M8.4 1.6C8.29391 1.6 8.19217 1.64214 8.11716 1.71716C8.04214 1.79217 8 1.89391 8 2C8 2.10609 8.04214 2.20783 8.11716 2.28284C8.19217 2.35786 8.29391 2.4 8.4 2.4H10.8C10.9061 2.4 11.0078 2.35786 11.0828 2.28284C11.1579 2.20783 11.2 2.10609 11.2 2C11.2 1.89391 11.1579 1.79217 11.0828 1.71716C11.0078 1.64214 10.9061 1.6 10.8 1.6H8.4Z"
  />
  <path
    d="M8 10.8C8 10.6939 8.04214 10.5922 8.11716 10.5172C8.19217 10.4421 8.29391 10.4 8.4 10.4H10.8C10.9061 10.4 11.0078 10.4421 11.0828 10.5172C11.1579 10.5922 11.2 10.6939 11.2 10.8C11.2 10.9061 11.1579 11.0078 11.0828 11.0828C11.0078 11.1579 10.9061 11.2 10.8 11.2H8.4C8.29391 11.2 8.19217 11.1579 8.11716 11.0828C8.04214 11.0078 8 10.9061 8 10.8Z"
  />
  <path
    d="M3.20001 4.8H4.00001V5.6H3.20001C2.98783 5.6 2.78435 5.68428 2.63432 5.83431C2.48429 5.98434 2.40001 6.18782 2.40001 6.4V11.6C2.40001 12.1304 2.61072 12.6391 2.98579 13.0142C3.36087 13.3893 3.86957 13.6 4.40001 13.6H9.60001C9.81218 13.6 10.0157 13.5157 10.1657 13.3657C10.3157 13.2157 10.4 13.0122 10.4 12.8V12H11.2V12.8C11.2 13.2243 11.0314 13.6313 10.7314 13.9314C10.4313 14.2314 10.0244 14.4 9.60001 14.4H4.40001C3.6574 14.4 2.94521 14.105 2.42011 13.5799C1.89501 13.0548 1.60001 12.3426 1.60001 11.6V6.4C1.60001 5.97565 1.76858 5.56868 2.06864 5.26862C2.36869 4.96857 2.77566 4.8 3.20001 4.8V4.8Z"
  />
</template>
