
import { defineComponent } from 'vue'
import { formatHours, formatMinutes, formatSeconds, formatCentiseconds } from '../helpers/formatters'

export default defineComponent({
  name: 'ProgramRunnerTimer',
  props: {
    time: { type: Number, required: true },
    isSmall: { type: Boolean, default: false },
    hideCentiseconds: { type: Boolean, default: false },
  },
  computed: {
    hours(): string {
      return formatHours(this.time / 1000)
    },
    minutes(): string {
      return formatMinutes(this.time / 1000)
    },
    seconds(): string {
      return formatSeconds(this.time / 1000)
    },
    centiseconds(): string {
      return formatCentiseconds(this.time / 1000)
    },
  },
})
